// SoundPlayer.js
const listAudio = [
    "https://firebasestorage.googleapis.com/v0/b/promete-it.appspot.com/o/asset%2Fsound%2Ffemale-evil-laugh-3-199546.mp3?alt=media&token=00753db7-0035-4453-a1c1-379a48cb2983",
    "https://firebasestorage.googleapis.com/v0/b/promete-it.appspot.com/o/asset%2Fsound%2Fshort-high-pitched-laugh-242960.mp3?alt=media&token=b06a70f3-6cde-4873-9409-25482c841d44",
    "https://firebasestorage.googleapis.com/v0/b/promete-it.appspot.com/o/asset%2Fsound%2Fwitch-103635.mp3?alt=media&token=b5ee0077-6be6-481c-9c6d-905853e65409",
    "https://firebasestorage.googleapis.com/v0/b/promete-it.appspot.com/o/asset%2Fsound%2Fhalloween-impact-05-93808.mp3?alt=media&token=b33c149d-71dd-4d64-8e6c-194a7230fafa",
];
const randomIndex = Math.floor(Math.random() * listAudio.length);
const audiochois = listAudio[randomIndex]

class SoundPlayer {
    constructor() {
        this.sound = new Audio(audiochois);
        this.sound.volume = 0.5; // Réglez le volume à 50 %
    }

    playSound() {
        this.sound.currentTime = 0; // Remet le son au début
        this.sound.play().catch((error) => {
            console.warn('Playback failed:', error);
        });
    }
}

export default new SoundPlayer(); // Exportez une instance de SoundPlayer