import React, {useEffect, useState} from 'react';
import '../styles/SplashScreen.css'; // Assurez-vous que ce fichier existe pour le style

const SplashScreen = () => {
    const [bats, setBats] = useState([]);

    useEffect(() => {
        const batCount = 15; // Augmentation du nombre de chauves-souris à 15
        const newBats = [];
        for (let i = 0; i < batCount; i++) {
            const batStyle = {
                top: `${Math.random() * 80}vh`, // Position Y aléatoire
                left: `${Math.random() * 100}vw`, // Position X aléatoire
                fontSize: `${Math.random() * 50 + 50}px`, // Taille aléatoire (50px à 100px)
                animationDuration: `${Math.random() * 2 + 1.5}s`, // Durée aléatoire de vol (2 à 4 secondes)
                animationTimingFunction: 'ease-in',
            };
            newBats.push(<div key={i} className="bat" style={batStyle}>🦇</div>);
        }
        setBats(newBats);
    }, []);

    return (
        <div className="splash-screen">
            {bats}
            <img src="/my logo.ico" alt="Logo" className="splash-icon" />
            <h1>🎃 joyeux Halloween 🎃</h1>
            <p>Chargement en cours...</p>
        </div>
    );
};

export default SplashScreen;
