// src/firebaseConfig.js

import {initializeApp} from "firebase/app";
import {getAuth} from "firebase/auth";
import {getAnalytics, setAnalyticsCollectionEnabled} from "firebase/analytics";
import {getDatabase} from "firebase/database";

// Configuration Firebase
const firebaseConfig = {
    apiKey: "AIzaSyDpLsFlJEk6oA94wrkHlEn8DuOzg67xNxw",
    authDomain: "promete-it.firebaseapp.com",
    databaseURL: "https://promete-it-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "promete-it",
    storageBucket: "promete-it.appspot.com",
    messagingSenderId: "87937879588",
    appId: "1:87937879588:web:6cb4fd345332b14e76d2b1",
    measurementId: "G-2FZ8GSY02W"
};

// Initialiser Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const analytics = getAnalytics(app);
const database = getDatabase(app);

// Désactiver la collecte jusqu'à ce que l'utilisateur accepte les cookies
setAnalyticsCollectionEnabled(analytics, false);

// Exporter les modules nécessaires
export { app, auth, analytics, database, setAnalyticsCollectionEnabled };
