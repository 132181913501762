import React, { lazy, startTransition, Suspense, useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { AnimatePresence } from 'framer-motion';
import Navbar from './components/Navbar';
import Footer from "./components/Footer";
import LegalMentions from "./pages/LegalMentions";
import NotFound from "./pages/404";
import PageTransition from './components/PageTransition';
import { analytics, setAnalyticsCollectionEnabled } from './firebaseConfig';
import { deleteCookie, getCookie, setCookie } from './cookieUtils';
import ScrollToTop from './components/ScrollToTop';
import './utils/carbonTracker';
import SplashScreen from "./components/SplashScreen";
import CookieBanner from "./CookieBanner";
import SoundPlayer from './components/SoundPlayer'; // Assurez-vous que le chemin est correct

// Lazy load your components
const Home = lazy(() => import('./pages/Home'));
const About = lazy(() => import('./pages/About'));
const CciData = lazy(() => import("./pages/Cci-data"));
const Maintenance = lazy(() => import("./pages/Services/Maintenance"));
const Consulting = lazy(() => import("./pages/Services/Consulting"));
const Development = lazy(() => import("./pages/Services/Development"));
const ImageConverter = lazy(() => import("./pages/ImageConverter"));

const App = () => {
    const [showSplash, setShowSplash] = useState(true);
    const soundPlayer = SoundPlayer; // Utiliser l'instance de SoundPlayer

    useEffect(() => {
        const minimumSplashTime = 2700; // 2.7 seconds
        const timer = setTimeout(() => {
            startTransition(() => {
                setShowSplash(false);
            });
        }, minimumSplashTime);
        return () => clearTimeout(timer);
    }, []);

    // Fonction pour jouer le son lorsque le Bubble est cliqué
    const handleBubbleClick = () => {
        soundPlayer.playSound();
    };

    useEffect(() => {
        // Initialize Google Analytics with consent mode
        ReactGA.initialize('G-2FZ8GSY02W', {
            gaOptions: {
                storage: 'none',
                cookieFlags: 'SameSite=None; Secure',
                consentMode: true
            }
        });
        console.log("Google Analytics initialisé avec le mode de consentement");
    }, []);

    const PageTracker = () => {
        const location = useLocation();
        useEffect(() => {
            const screenResolution = `${window.screen.width}x${window.screen.height}`;
            const viewportSize = `${window.innerWidth}x${window.innerHeight}`;
            const language = navigator.language || navigator.userLanguage;
            const userAgent = navigator.userAgent;
            const userId = getUserId();

            ReactGA.send({
                hitType: 'pageview',
                page: location.pathname + location.search,
                title: document.title,
                screenResolution: screenResolution,
                viewportSize: viewportSize,
                language: language,
                userAgent: userAgent,
                userId: userId,
            });

            console.log("Page tracked:", location.pathname + location.search, "Title:", document.title, "Resolution:", screenResolution, "Viewport:", viewportSize, "Language:", language, "User Agent:", userAgent);

        }, [location]);

        return null;
    };

    const getUserId = () => {
        let userId = getCookie('userId');
        if (!userId) {
            userId = generateUserId();
            setCookie('userId', userId, { sameSite: 'Strict', secure: true });
            console.log("New userId generated and stored in cookies:", userId);
        } else {
            console.log("Existing userId found in cookies:", userId);
        }
        return userId;
    };

    const generateUserId = () => {
        return 'user_' + Math.random().toString(36).substr(2, 9);
    };

    return (
        <Router>
            <ScrollToTop />
            <PageTracker />
            {showSplash ? (
                <SplashScreen />
            ) : (
                <Suspense fallback={<Navbar />}>
                    <div>
                        <Navbar />
                        <AnimatePresence mode="wait">
                            <PageTransition>
                                <Routes>
                                    <Route path="/" element={<Home handleClick={handleBubbleClick} />} />
                                    <Route path="/about" element={<About handleClick={handleBubbleClick} />} />
                                    <Route path="/mentions-legales" element={<LegalMentions handleClick={handleBubbleClick} />} />
                                    <Route path="/CCI" element={<CciData handleClick={handleBubbleClick} />} />
                                    <Route path="/convertisseur-images" element={<ImageConverter handleClick={handleBubbleClick} />} />
                                    <Route path="/consulting" element={<Consulting handleClick={handleBubbleClick} />} />
                                    <Route path="/development" element={<Development handleClick={handleBubbleClick} />} />
                                    <Route path="/maintenance" element={<Maintenance handleClick={handleBubbleClick} />} />
                                    <Route path="*" element={<NotFound handleClick={handleBubbleClick} />} />
                                </Routes>
                            </PageTransition>
                        </AnimatePresence>
                        <CookieBanner />
                        <Footer />
                    </div>
                </Suspense>
            )}
        </Router>
    );
};

export default App;