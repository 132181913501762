// src/index.js
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);

reportWebVitals();

// Enregistrement du Service Worker avec gestion des mises à jour
if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
        navigator.serviceWorker.register('/service-worker.js').then(registration => {
            console.log('ServiceWorker registration successful with scope: ', registration.scope);

            // Vérification des mises à jour
            registration.onupdatefound = () => {
                const installingWorker = registration.installing;
                installingWorker.onstatechange = () => {
                    if (installingWorker.state === 'installed') {
                        if (navigator.serviceWorker.controller) {
                            // Nouvelle version disponible
                            console.log('Nouvelle version disponible, actualisez la page pour l\'utiliser.');
                        } else {
                            // Première installation du Service Worker
                            console.log('Le contenu est maintenant mis en cache pour une utilisation hors ligne.');
                        }
                    }
                };
            };
        }).catch(error => {
            console.error('ServiceWorker registration failed: ', error);
        });
    });
}
