// cookieUtils.js

// Importation de la bibliothèque js-cookie
import Cookies from 'js-cookie';

// Fonction pour obtenir un cookie
export const getCookie = (name) => {
    return Cookies.get(name);
};

// Fonction pour définir un cookie
export const setCookie = (name, value, options = {}) => {
    // Définir des options de sécurité par défaut pour les cookies
    const secureOptions = {
        expires: 365,        // Le cookie expirera dans 365 jours
        sameSite: 'Strict',  // Désactive les cookies tiers
        secure: true,        // Assure que le cookie est envoyé uniquement sur HTTPS
        ...options           // Permet de surcharger les options par défaut
    };
    Cookies.set(name, value, secureOptions);
};

// Fonction pour supprimer un cookie
export const deleteCookie = (name) => {
    Cookies.remove(name, { sameSite: 'Strict', secure: true });
};
